<template>
  <area-container>
    <area-nav :links="links"></area-nav>
    <area-content class="layout">
      <router-view></router-view>
    </area-content>
  </area-container>
</template>

<script>
export default {
  name: "ReportsLayout",
  data() {
    return {
      links: [
        { route: "reports", name: "Rapporter" },
      ]
    }
  }
}
</script>


<style scoped>
/* Styling for subcomponent reports */
>>> th {
  @apply p-3 leading-4 bg-gradient-to-b from-gray-500 to-gray-600 text-left;
  position: relative;
}

>>> th.right {
  @apply text-right;
}

>>> tr {
  background-color: theme('colors.gray.50');
}

>>> tr.odd {
  background-color: theme('colors.gray.100')
}

>>> td {
  @apply px-3 py-2;
  border-bottom: 1px solid theme('colors.gray.300');
  border-right: 1px solid theme('colors.gray.300');
}

>>> tbody tr {
  cursor: pointer;
}

</style>